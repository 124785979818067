import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    // display: 'flex',
    // flexWrap: 'wrap',
    '& > *': {
      // margin: theme.spacing(1),
      // width: theme.spacing(16),
      // height: theme.spacing(16),
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }
}));

export default function VerticalLinearStepper(props) {
  let { id } = useParams();

  const [program, setProgram] = useState({ challenges: [] });
  useEffect(() => {
    fetch(`/.netlify/functions/program?id=${ id }`)
      .then(res => res.json())
      .then(
        result => {
          setProgram(result);
        },
        console.error
       )
  }, []);


  const classes = useStyles();

const palette = [
[
'#E12485',
'#FF7859',
],
[
'#FF4C6D',
'#FFA54D',
],
[
'#B14EC3',
'#FFD053',
],
[
'#3E71E6',
'#FFA54D',
],
[
'#0084E3',
'#E12485',
],
];

  const button = (i) => ({
    color: 'white',
    fontWeight: 'bold',
    textDecoration: 'none',
    background: '#FF4C6D',
    background: `linear-gradient(45deg, #FF4C6D 0%, #FFA54D 100%)`,
  });

  return (
    <div className={classes.root}>
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
         {program.author}
        </Typography>
        <Typography variant="h6" component="h6">
          {program.name}
        </Typography>
        <Typography variant="body2" component="p">
          {program.shortDescription}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">więcej</Button>
      </CardActions>
    </Card>
    {program.challenges.map((pro, i) => (
      <Link
      key={pro.name}
      style={{ textDecoration: 'none' }}
      to={{
        pathname: `/challenge/${ pro.id }`,
        state: pro
      }}>
      <Paper
      elevation={3}
      className={classes.button}
      style={button(i)}>
      {pro.name}
      </Paper>
      </Link>
      ))}
    </div>
  );
}

