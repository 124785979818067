import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
// import { autoPlay } from 'react-swipeable-views-utils';

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 400,
    flexGrow: 1,
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  background: {
    display: 'block',
    // maxWidth: 400,
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    minHeight: '300px',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '40px',
    boxSizing: 'border-box',
    fontSize: '20px',
    lineHeight: '27px',
  },
  close: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  }
}));


const palette = [
[
'#845EC2',
'#D65DB1',
],
[
'#D65DB1',
'#FF9671',
],
[
'#FF6F91',
'#FF6F91',
],
[
'#FF9671',
'#2C73D2',
],

[
'#845EC2',
'#FFC75F',
],
[
'#0089BA',
'#2C73D2',
],
[
'#0081CF',
'#0081CF',
],
[
'#0089BA',
'#008F7A',
],
[
'#008E9B',
'#FF7859',
],

[
'#E12485',
'#008E9B',
],
[
'#FF4C6D',
'#FFA54D',
],
[
'#B14EC3',
'#FFD053',
],
[
'#3E71E6',
'#FFA54D',
],
[
'#0084E3',
'#E12485',
],
];

  const background = (i) => ({
    color: 'white',
    fontWeight: 'bold',
    textDecoration: 'none',
    background: palette[i][0],
    background: `linear-gradient(45deg, ${ palette[i][0] } 0%, ${ palette[i][1] } 100%)`,
  });


function SwipeableTextMobileStepper() {
  const [step, setStep] = useState({
    com: [],
    him: [],
    her: [],
    ask: ['###'],
    type: 'com',
  });
  let history = useHistory();
  let { id } = useParams();

  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  // const myFunction = async () =>
  //  await (await fetch('/.netlify/functions/step')).json();

  useEffect(() => {
    fetch(`/.netlify/functions/challenge?id=${ id }`)
      .then(res => res.json())
      .then(
        result => {
          let him = [];
          let her = [];
          let com = [];
          if (result.cards.includes('###')) {
            let [him_cards, her_cards] = result.cards.split('\n###\n');
            console.log(him_cards)
            console.log(her_cards)
            him_cards.split('\n').forEach(res => {
              him.push(res)
            })
            her_cards.split('\n').forEach(res => {
              her.push(res)
            });
            him.push('###');
            her.push('###');
            setStep({
              ...step,
              him,
              her,
              type: 'ask',
            })
          } else {
            result.cards.split('\n').forEach(res => {
              com.push(res)
            });
            com.push('koniec');
            setStep({
              ...step,
              com,
            });
          }
        },
        console.error
       )
  }, []);

  const take = type => {
    setStep({ ...step, type });
    setActiveStep(0);
  }

  return (
    <div className={classes.root}>
      <Button className="btn-next" onClick={handleNext} disabled={activeStep === step[step.type].length - 1}>
        {theme.direction === 'rtl' ? <KeyboardArrowLeft style={{ fontSize: 50 }} /> : <KeyboardArrowRight style={{ fontSize: 50 }} />}
      </Button>
      <Button className="btn-prev" onClick={handleBack} disabled={activeStep === 0}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight style={{ fontSize: 50 }} /> : <KeyboardArrowLeft style={{ fontSize: 50 }} />}
      </Button>
       <CloseIcon onClick={() => history.goBack()} className="btn-close" />
       <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {step[step.type].map((s, i) => (
          <div key={s}>
            {Math.abs(activeStep - i) <= 2 ? (
              <div key={i} className={classes.background} style={background(i)}>
                { s != '###' ? s : (
                  <div>
                  <Button onClick={() => take('him')} size="large" style={{ color: 'white', borderColor: 'white', }} variant="outlined">On</Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button onClick={() => take('her')} size="large" style={{ color: 'white', borderColor: 'white', }} variant="outlined">Ona</Button>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={step[step.type].length}
        position="top"
        variant="progress"
        activeStep={activeStep}
        style={{background: 'transparent' }}
      />
    </div>
  );
};

export default SwipeableTextMobileStepper;