import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    // display: 'flex',
    // flexWrap: 'wrap',
    '& > *': {
      // margin: theme.spacing(1),
      // width: theme.spacing(16),
      // height: theme.spacing(16),
    },
  },
  button: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }
}));

const palette = [
[
'#E12485',
'#FF7859',
],
[
'#FF4C6D',
'#FFA54D',
],
[
'#B14EC3',
'#FFD053',
],
[
'#3E71E6',
'#FFA54D',
],
[
'#0084E3',
'#E12485',
],
];

function App() {
  const [program, setProgram] = useState([]);
  useEffect(() => {
    fetch(`/.netlify/functions/program`)
    .then(res => res.json())
    .then(res => {
      setProgram(res);
    },
    console.error
    )
  }, []);

  const classes = useStyles();
  const button = (i) => ({
    color: 'white',
    fontWeight: 'bold',
    textDecoration: 'none',
    background: '#E12485',
    background: `linear-gradient(45deg, #E12485 0%, #FF7859 100%)`,
  });

  return (
    <div className={classes.root}>
    <Paper
    elevation={3}
    className={classes.button}>
    <h1>Toba</h1>
    <br />
    <br />
    </Paper>

    {program.map((pro, i) => (
      <Link
      key={pro.id}
      style={{ textDecoration: 'none' }}
      to={{
        pathname: `/program/${ pro.id }`,
        state: pro
      }}>
      <Paper
      elevation={3}
      className={classes.button}
      style={button(i)}>
      {pro.fields.name}
      </Paper>
      </Link>
      ))}
    </div>
    );
};

export default App;
