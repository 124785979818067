import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  const [challenge, setChallenge] = useState([]);

  // const myFunction = async () =>
  //  await (await fetch('/.netlify/functions/challenge')).json();

  useEffect(() => {
    fetch('/.netlify/functions/challenge')
      .then(res => res.json())
      .then(
        result => {
          setChallenge(result);
          // setIsLoaded(true);
          // setItems(result.items);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          // setIsLoaded(true);
          // setError(error);
        }
      )
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          { JSON.stringify(challenge, null, 2) }
        </a>
      </header>
    </div>
  );
}

export default App;
